.card-checkbox {
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
}

.review-sidebar {
  width: 240px;
  height: 86vh;
  background-color: #fff;
  overflow: auto;
  border-right: 1px solid #ccc;
  padding: 0.5rem;
}

.review-content {
  height: 86vh;
  background-color: #fff;
  width: 100%;
  overflow: auto;
}

.menu-active {
  color: #fff;
}

.menu-default {
  color: #a7a8bb;
}
.map-top-box {
  background-color: #ffffff;
  border-radius: 4px;
  height: 45vh;
  position: fixed;
  z-index: 100;
  width: 316px;
  overflow: auto;
  margin-left: 30px;
  margin-top: 30px;
  box-shadow: 0 3px 6px rgb(0 0 0/30%);
}

.map-top-box-direction {
  display: flex;
  flex-direction: column;
}

.alert-top-box,
.alert-second-box {
  background-color: #ffffff;
  border-radius: 4px;
  height: 45vh;
  width: 316px;
  overflow: auto;
  box-shadow: 0 3px 6px rgb(0 0 0/30%);
}

.card {
  background-color: #ffffff;
  border-radius: 4px;
  overflow: auto;
  box-shadow: 0 3px 6px rgb(0 0 0/30%);
}
.card-padding-1 {
  padding: 18px;
}

.map-second-box {
  background-color: #ffffff;
  border-radius: 4px;
  height: 45vh;
  position: fixed;
  z-index: 100;
  width: 316px;
  overflow: auto;
  top: 50vh;
  margin-left: 30px;
  box-shadow: 0 3px 6px rgb(0 0 0/30%);
}

.sub-label {
  font-size: 11px;
  color: #bfbfbf;
}

.item-text {
  font-size: 12px;
  color: #3f4254;
}

.float-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 5px;
}

.title {
  font-weight: 600;
  font-size: 18px;
}

.float-box .title {
  margin-left: 12px;
}
.input-search {
  border: none;
  padding: 4px 6px;
  width: 100%;
}
.input-search:focus {
  outline: none;
}

.map-second-box .header {
  padding: 10px 20px 5px 20px;
}

.map-second-box .header .title {
  font-weight: 600;
  font-size: 18px;
}
.second-box-item {
  padding: 10px 20px 10px 20px;
}
.sc-search-box {
  padding: 0 20px 0 20px;
}
.border-left-success {
  border-left: 3px solid #67ad5b;
}
.border-left-dangerous {
  border-left: 3px solid #b50000;
}

.border-left-warning {
  border-left: 3px solid #ffc105;
}

.border-left-offline {
  border-left: 3px solid #a7a8bb !important;
}

.border-left-online {
  border-left: 3px solid #83cd3d !important;
}

.box-evidence-detail {
  background-color: #ffffff;
  border-radius: 4px;
  min-height: 45vh;
  position: fixed;
  z-index: 100;
  width: 316px;
  overflow: auto;
  left: 626px;
  margin-top: 30px;
  box-shadow: 0 3px 6px rgb(0 0 0/30%);
}
.box-evidence-detail2 {
  background-color: #ffffff;
  border-radius: 4px;
  min-height: 30vh;
  z-index: 100;
  width: 316px;
  overflow: auto;
  box-shadow: 0 3px 6px rgb(0 0 0/30%);
}
.box-select-device {
  background-color: #ffffff;
  border-radius: 4px;
  min-height: 45vh;
  position: fixed;
  z-index: 99999;
  width: 316px;
  overflow: auto;
  left: 966px;
  margin-top: 30px;
  box-shadow: 0 3px 6px rgb(0 0 0/30%);
}

.session {
  padding: 20px;
}
.haft-session {
  padding: 10px 20px 0;
}
.haft-session2 {
  padding: 0 15px;
}

.session-header {
  padding: 13px 20px;
}
.cursor-pointer {
  cursor: pointer;
}

.btn-close {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  z-index: 999;
}
.evidence-popup {
  width: 261px;
}
.map-position {
  color: #4994ec;
}
.status-icon {
  width: 16px;
}
.device-marker {
  padding: 3px;
}
.menu-icon {
  width: 20px;
}
.long-text {
  max-width: 400px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.modal-long-text {
  display: block;
  max-width: 180px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.page-title {
  font-size: 22px;
}
.page-title-giat {
  font-size: 20px;
  line-height: 27.24px;
  letter-spacing: 1%;
  font-weight: 600;
}
.box-shadow-information {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.2),
    0px 2px 5px rgba(0, 0, 0, 0.15);
}
.modal-title {
  margin: 0 0 57px;
}
.device-item {
  border: 1px solid #efefef;
  padding: 4px 9px;
  margin-bottom: 8px;
  border-radius: 5px;
  font-size: 14px;
}
.device-item:last-child {
  margin-bottom: 14px;
}
.modal-content {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 400px;
}
.modal-footer {
  height: 80px;
}
.modal-footer-sm {
  height: 71px;
}
.add-device-title {
  font-size: 22px;
  padding: 18px;
  margin: 0;
}
.device-list {
  max-height: 440px;
  overflow: auto;
}
.map-container {
  height: 100vh;
  width: auto;
}
.box-container {
  background-color: #fff;
}
.ev-detail-main {
  padding: 25px;
}
.ev-top-header {
  margin-bottom: 15px;
}
.ev-media {
  padding: 0 25px;
}
.pat-media {
  padding-left: 0px;
  padding-right: 5px;
}
.log-item {
  border: 1px solid #efefef;
  padding: 10px;
  margin-bottom: 8px;
  font-size: 14px;
}
.log-item span {
  display: flex;
  min-width: 190px;
}
.carousel {
  height: 200px;
}
.carousel-lg {
  min-height: 500px;
}
.group {
  margin-bottom: 10px;
  font-size: 14px;
  color: #3f4254;
}
.color-text-default {
  color: #3f4254;
}

.group label {
  font-size: 12px;
  color: #6f7687;
}
.ptr-sub-box {
  padding: 17px 60px;
}
.evidence_marker {
  cursor: pointer;
}
.mapboxgl-popup-content {
  width: fit-content;
}
.mapboxgl-popup {
  max-width: fit-content !important;
  z-index: 1;
}
.video-item {
  height: 200px;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.video-item-full {
  height: 100%;
}
.play-icon {
  cursor: pointer;
  top: calc(50% - 48px);
  position: relative;
}
.dummy-background {
  background-color: #010101;
  position: absolute;
  width: 100%;
  height: 100%;
}
.dummy-background-off {
  background-color: #72757b;
  background-image: linear-gradient(#484d54, #7c7f84);
}
.menu-control {
  width: 100%;
  position: absolute;
  padding: 15px;
  top: 0;
  color: #fff;
}

.dispatch-evidence-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: #fff;
  border-radius: 10px;
  padding: 49px 60px 10px;
}
#ReactSimpleImageViewer {
  z-index: 9999;
}

.live-detail-right {
  max-height: 660px;
  overflow: auto;
}
.popup-group-2 {
  margin-bottom: 5px;
}
.popup-group-2 label {
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
}

.popup-group-2 .sub-label {
  font-size: 10px;
  color: #6f7687;
}
.device-detail-content {
  max-height: 63vh;
  overflow: auto;
}
.box-evidence-detail .session-header {
  padding: 5px 20px;
}
.tree-action {
  display: none;
}
.tree-label:hover > .tree-action {
  display: inline-block;
  vertical-align: middle;
}

.image-wrap {
  background-color: #f2f2f2;
  width: 100%;
  height: 500px;

  text-align: center; /* horizontal center */
  vertical-align: middle; /* vertical center */
}
.image-wrap img,
.image-wrap2 img,
.image-wrap3 img,
.image-wrap4 img {
  vertical-align: middle; /* also needed to adjust better inside table-cell */
  -o-object-fit: contain;
  object-fit: contain;
  max-height: 100%; /* height can be maximum of container, maintains ratio */
  max-width: 100%; /* width can be maximum of container, maintains ratio */
  height: 100%;
  width: 100%;
}

.image-wrap2 {
  background-color: #f2f2f2;
  width: 90px;
  height: 90px;

  text-align: center; /* horizontal center */
  vertical-align: middle; /* vertical center */
}

.image-wrap3 {
  background-color: #f2f2f2;
  width: 55px;
  height: 55px;
  border-radius: 4px;

  text-align: center; /* horizontal center */
  vertical-align: middle; /* vertical center */
}

.image-wrap4 {
  align-items: center;
  background-color: #ffffff;
  width: 100%;
  height: 65px;
  border-radius: 4px;

  text-align: center; /* horizontal center */
  vertical-align: middle; /* vertical center */
}
.full-opacity {
  opacity: 1 !important;
}
.color-wrap {
  width: 198px;
  padding: 6px 0 0 6px;
  display: flex;
  flex-wrap: wrap;
}
.item-color {
  width: 18px;
  height: 18px;
  margin-right: 6px;
  margin-bottom: 6px;
  cursor: pointer;
}
.tree-action {
  display: none;
}
.tree-label:hover > .tree-action {
  display: inline-block;
  vertical-align: middle;
}
.tip-event div {
  padding-bottom: 4px;
  padding-top: 10px;
}
.tip-event div:first-child {
  padding-top: 0;
}
.horizontal-label {
  margin-top: 10px;
}

.evidence-remove-wrap {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.evidence-remove-image {
  display: none;
}

.image-wrap:hover .evidence-remove-image {
  display: block;
}

.icon-history-download {
  display: none;
}

.history-item:hover .icon-history-download {
  display: block;
}

.history-item:hover {
  background-color: #f2f2f2;
}

.history-line {
  position: relative;
  margin-right: 7px;
}

.history-line:after {
  content: '';
  position: absolute;
  width: 3px;
  background-color: #2196f3;
  left: 50%;
  margin-left: -1px;
}
.hl-first:after {
  height: 50%;
  bottom: 0;
}

.hl-body:after {
  top: 0;
  bottom: 0;
}

.hl-end:after {
  height: 50%;
  top: 0;
}

@media only screen and (max-width: 1366px) {
  .dispatch-evidence-modal {
    width: 500px;
    padding: 29px 40px 0;
  }

  .modal-title {
    margin: 0 0 27px;
  }

  .modal-footer {
    height: 20px;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  .mb-4 {
    margin-bottom: 0.75rem;
  }
  .device-list {
    max-height: 300px;
  }

  .map-top-box {
    margin-left: 25px;
    margin-top: 25px;
  }

  .map-second-box {
    top: 53vh;
    margin-left: 25px;
  }

  .box-evidence-detail,
  .box-select-device {
    margin-top: 25px;
  }
  .video-item {
    height: 160px;
  }

  .video-item-full {
    height: 100%;
  }

  .live-detail-right {
    max-height: 490px;
  }
  .device-detail-content {
    max-height: 48vh;
  }
}
