a {
  text-decoration: none;
}
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a7a8bb;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a7a8bb;
}
.logo {
  width: 47px;
}
.site-name {
  font-weight: 600;
  font-size: 14px;
}
.side-bar-text {
  color: #a7a8bb;
}
h2 {
  color: #3f4254;
}

.mb-20 {
  margin-bottom: 20px;
}
.card-icon {
  margin-right: 5px;
  margin-top: -2px;
}
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.relative {
  position: relative;
}
.my-2 {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.my-4 {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.my-6 {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.w-full {
  width: 100%;
}

label {
  color: #6f7687;
}
.block {
  display: block;
}
.justifyContentCenter {
  justify-content: center;
}
.justifyContentBetween {
  justify-content: space-between;
}
.justifyContentEnd {
  justify-content: flex-end;
}
.alignItemsCenter {
  align-items: center;
}
.subTextColor {
  color: #a7a8bb;
}
.input-search::placeholder {
  color: #a7a8bb;
}
.textColor {
  color: #3f4254;
}
.text-center {
  text-align: center;
}
.thHeader {
  font-weight: 600;
  color: #3f4254;
  font-size: 14px;
}
.thHeaderGiat {
  font-weight: 700;
  color: #000000;
  font-size: 14px;
  line-height: 16.34px;
}
.color-blue {
  color: #4994ec;
}
.color-list {
  color: #67ad5b;
}
.color-success {
  color: #67ad5b;
}

.color-dangerous {
  color: #f64e60;
}
.color-online {
  color: #59ae85;
}
.color-primary {
  color: #ef6c00;
}
.color-clocked-in {
  color: #83cd3d;
}
.color-clocked-late {
  color: #ffc105;
}
.color-no-status {
  color: #a7a8bb;
}
.color-device-live {
  color: #7cd729;
}
.color-device-off {
  color: #e82809;
}

.font-size-14 {
  font-size: 14px;
}
.font-size-13 {
  font-size: 13px;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-11 {
  font-size: 11px;
}
.font-bold {
  font-weight: 600;
}
.mb-sub-2 {
  margin-bottom: 2px;
}
.inline-block {
  display: inline-block;
}
.flex-grow-1 {
  flex-grow: 1;
}
.text-capitalize {
  text-transform: capitalize;
}
.nowrap {
  white-space: nowrap;
}
.swal2-container {
  z-index: 9999 !important;
}
#nprogress .bar {
  z-index: 9999 !important;
}
.popup-group {
  margin-bottom: 10px;
}
.popup-group label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #3f4254;
  display: inline-block;
}
.activity-log {
  max-height: 300px;
  overflow: auto;
}
h2 {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}
.round {
  border-radius: 0.25rem;
}
.round-md {
  border-radius: 0.375rem;
}
.round-lg {
  border-radius: 0.5rem;
}
.hidden {
  display: none;
}
